<template>
  <div class="post-button" v-on:click="onClick">
    <v-icon>icon-post</v-icon>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';

export default {
  components: {
  },
  methods: {
    onClick() {
      if (Capacitor.isNativePlatform()) {
        window.open("https://amatome.town.ama.shimane.jp/request");
      } else {
        this.$emit('change-tab', 'post');
      }
    },
  },
}
</script>

<style scoped>
.post-button {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 10px;
  bottom: calc(env(safe-area-inset-bottom) + 82px);
  border-radius: 50%;
  background: #d0a638;
  text-align: center;
  filter: drop-shadow(0px 3px 4px #777);
}

.post-button:after {
  position: relative;
  top: 0px;
  content: "投稿";
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 0;
  text-shadow:
    1px 1px 0px #d0a638,
    -1px 1px 0px #d0a638,
    1px -1px 0px #d0a638,
    -1px -1px 0px #d0a638,
    1px 0px 0px #d0a638,
    0px 1px 0px #d0a638,
    -1px 0px 0px #d0a638,
    0px -1px 0px #d0a638;
}

.post-button>i {
  color: #fff;
  width: 100%;
}

.post-button>i::before {
  font-size: 30px;
  line-height: 50px;
}
</style>