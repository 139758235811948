<template>
  <div id="home">
    <ProblemReportDialog
      :visibleDialog="dialog"
      :article="selectedArticle"
      @result="dialogResponse"
    />

    <v-navigation-drawer v-model="drawer" app fixed temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>記事をシェア</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-item link>
          <a
            class="export-item"
            :href="
              selectedArticle
                ? 'https://www.facebook.com/sharer/sharer.php?u=' +
                  selectedArticle.url
                : null
            "
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-list-item-icon>
              <v-icon>mdi-facebook</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Facebook でシェアする</v-list-item-title>
            </v-list-item-content>
          </a>
        </v-list-item>
        <v-list-item link>
          <a
            class="export-item"
            :href="
              selectedArticle
                ? 'https://twitter.com/share?text=' +
                  selectedArticle.title +
                  ' / ' +
                  selectedArticle.source +
                  '&url=' +
                  selectedArticle.url
                : null
            "
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-list-item-icon>
              <v-icon>mdi-twitter</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Twitter でシェアする</v-list-item-title>
            </v-list-item-content>
          </a>
        </v-list-item>
        <v-list-item link>
          <a
            class="export-item"
            v-clipboard:copy="selectedArticle ? selectedArticle.url : ''"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <v-list-item-icon>
              <v-icon>mdi-link-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>リンクをコピーする</v-list-item-title>
            </v-list-item-content>
          </a>
        </v-list-item>
        <v-list-item link>
          <a class="export-item" @click.stop="dialog = true">
            <v-list-item-icon>
              <v-icon>mdi-comment-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>記事の問題を報告</v-list-item-title>
            </v-list-item-content>
          </a>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="header-fixed">
      <h1>
        <img
          src="@/assets/header.svg"
          alt="海士町の「いま」をまとめました あまとめ"
        />
      </h1>
      <div v-show="currentTab === 'top'" class="main-tab-menu news-tab-menu">
        <div class="main-tab-menu-inner">
          <div
            class="main-tab-menu-item"
            @click="newsTab = 'news'"
            :class="menuNewsTab1Class"
          >
            ニュース
          </div>
          <div
            class="main-tab-menu-item"
            @click="newsTab = 'ip'"
            :class="menuNewsTab3Class"
          >
            島内告知
          </div>
          <div
            class="main-tab-menu-item"
            @click="newsTab = 'featured'"
            :class="menuNewsTab2Class"
          >
            特集
          </div>
        </div>
      </div>
      <div
        v-show="currentTab === 'event'"
        id="event-tab"
        class="main-tab-title"
      >
        <div class="main-tab-title-inner">イベント</div>
      </div>
      <div v-show="currentTab === 'link'" id="link-tab" class="main-tab-title">
        <div class="main-tab-title-inner">リンク</div>
      </div>
      <div v-show="currentTab === 'menu'" id="menu-tab" class="main-tab-title">
        <div class="main-tab-title-inner">メニュー</div>
      </div>
    </div>

    <section
      v-show="currentTab === 'top'"
      id="tab1"
      ref="tab1"
      class="tab-content"
      :style="tab1Styles"
    >
      <v-tabs-items v-model="newsTab" class="tab-inner">
        <News
          :active="currentTab === 'top' || isFlipping"
          :loading="loading"
          :currentNewsTab="newsTab"
          :topics="topics"
          :raw-posts="rawPosts"
          :featuredPosts="featuredPosts"
          :tags="tags"
          :weather="weather"
          :links="links"
          @reload="reload"
          @share="share"
        />
      </v-tabs-items>
    </section>
    <section
      v-show="currentTab === 'event'"
      id="tab2"
      ref="tab2"
      class="tab-content"
      :style="tab2Styles"
    >
      <Calendar :active="currentTab === 'event' || isFlipping" />
    </section>
    <section
      v-show="currentTab === 'link'"
      id="tab3"
      ref="tab3"
      class="tab-content"
      :style="tab3Styles"
    >
      <Links :links="links" :active="currentTab === 'link' || isFlipping" />
    </section>
    <section
      v-show="currentTab === 'menu'"
      id="tab4"
      ref="tab4"
      class="tab-content"
      :style="tab4Styles"
    >
      <Menu :active="currentTab === 'menu' || isFlipping" :menus="menus" />
    </section>
    <section
      v-show="currentTab === 'post'"
      id="tab5"
      ref="tab5"
      class="tab-content"
      :style="tab5Styles"
    >
      <RequestForm
        :active="currentTab === 'post' || isFlipping"
        @change-tab="changeTab"
        :menus="menus"
      />
    </section>
    <div class="page-line" :style="pageLinestyles"></div>
    <PostButton v-show="currentTab !== 'post'" @change-tab="changeTab" />
    <BottomNavigation @change-tab="changeTab" :currentTab="currentTab" />
  </div>
</template>

<script>
import Vue from "vue";
import Links from "./links/Links";
import BottomNavigation from "./navigation/BottomNavigation";
import Menu from "./navigation/Menu";
import News from "./news/News";
import Calendar from "./calendar/Calendar";
import PostButton from "./ui/PostButton";
import ProblemReportDialog from "./ProblemReportDialog";
import RequestForm from "../views/RequestForm";
import "@/assets/icomoon/style.css";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

export default {
  props: [
    "topics",
    "rawPosts",
    "featuredPosts",
    "tags",
    "loading",
    "links",
    "weather",
    "menus",
  ],
  components: {
    News,
    Calendar,
    Links,
    Menu,
    BottomNavigation,
    PostButton,
    RequestForm,
    ProblemReportDialog,
  },
  name: "home-view",
  data() {
    return {
      currentTab: "top",
      isFlipping: false,
      flippingDirection: "",
      touchStartPosX: 0,
      pageLinestyles: {
        left: "-30px",
        visibility: "hidden",
      },
      tab1Styles: {
        width: "100vw",
      },
      tab2Styles: {
        width: "100vw",
      },
      tab3Styles: {
        width: "100vw",
      },
      tab4Styles: {
        width: "100vw",
      },
      tab5Styles: {
        width: "100vw",
      },
      tabHeight: 0,
      tab1ScrollY: 0,
      drawer: null,
      dialog: false,
      message: "",
      errorMessage: "",
      selectedArticle: null,
      newsTab: "news",
    };
  },
  created: function () {
    let tabParam = this.$route.query.tab;
    if (tabParam) {
      this.currentTab = tabParam;
      if (this.currentTab === "top") {
        let newsTabParam = this.$route.query.news_type;
        if (newsTabParam) {
          this.newsTab = newsTabParam;
        }
      }
    }
  },
  mounted: function () {
    //this.changeTab(this.currentTab);
  },
  updated: function () {},
  computed: {
    screenWidth() {
      return screen.availWidth;
    },
    moveThresholdWidth() {
      return screen.availWidth / 10;
    },
    flipThresholdWidth() {
      return screen.availWidth / 3;
    },

    menuNewsTab1Class() {
      return { active: this.newsTab === "news" };
    },
    menuNewsTab2Class() {
      return { active: this.newsTab === "featured" };
    },
    menuNewsTab3Class() {
      return { active: this.newsTab === "ip" };
    },
  },

  methods: {
    getScrollTop() {
      return document.documentElement.scrollTop || document.body.scrollTop;
    },

    setScrollTop(scrollTop) {
      let tgt;
      if ("scrollingElement" in document) {
        tgt = document.scrollingElement;
      } else if (this.browser.isWebKit) {
        tgt = document.body;
      } else {
        tgt = document.documentElement;
      }
      tgt.scrollTop = scrollTop;
    },
    changeTab(newTab) {
      if (newTab === "top") {
        this.tab1Styles.width = "100vw";
        this.tab2Styles.width = "100vw";
        this.tab3Styles.width = "100vw";
        this.tab4Styles.width = "100vw";
        setTimeout(
          function () {
            let positionY = sessionStorage.getItem("positionY");
            this.setScrollTop(positionY);
          }.bind(this),
          300
        );

        let newsTypeParam = this.$route.query.news_type;
        let tagParam = this.$route.query.tag;

        var newQuery = { tab: "top" };
        if (newsTypeParam) {
          newQuery.news_type = newsTypeParam;
          if (tagParam) {
            newQuery.tag = tagParam;
          }
        }

        this.$router.push({ path: "/", query: newQuery }).catch(() => {});
      } else if (newTab === "event") {
        if (this.currentTab === "top") {
          let positionY = this.getScrollTop();
          sessionStorage.setItem("positionY", positionY);
        }
        this.setScrollTop(0);
        this.tab1Styles.width = "0";
        this.tab2Styles.width = "100vw";
        this.tab3Styles.width = "100vw";
        this.tab4Styles.width = "100vw";
        this.$router
          .push({ path: "/", query: { tab: "event" } })
          .catch(() => {});
      } else if (newTab === "link") {
        if (this.currentTab === "top") {
          let positionY = this.getScrollTop();
          sessionStorage.setItem("positionY", positionY);
        }
        this.setScrollTop(0);
        this.tab1Styles.width = "0";
        this.tab2Styles.width = "0";
        this.tab3Styles.width = "100vw";
        this.tab4Styles.width = "100vw";
        this.$router
          .push({ path: "/", query: { tab: "link" } })
          .catch(() => {});
      } else if (newTab === "menu") {
        if (this.currentTab === "top") {
          let positionY = this.getScrollTop();
          sessionStorage.setItem("positionY", positionY);
        }
        this.setScrollTop(0);
        this.tab1Styles.width = "0";
        this.tab2Styles.width = "0";
        this.tab3Styles.width = "0";
        this.tab4Styles.width = "100vw";
        this.$router
          .push({ path: "/", query: { tab: "menu" } })
          .catch(() => {});
      } else if (newTab === "post") {
        if (this.currentTab === "top") {
          let positionY = this.getScrollTop();
          sessionStorage.setItem("positionY", positionY);
        }
        this.setScrollTop(0);
        this.tab1Styles.width = "0";
        this.tab2Styles.width = "0";
        this.tab3Styles.width = "0";
        this.tab4Styles.width = "0";
        this.$router
          .push({ path: "/", query: { tab: "post" } })
          .catch(() => {});
      }
      this.currentTab = newTab;
    },
    reload() {
      this.$emit("reload");
    },
    onCopy() {
      alert("リンクがクリップボードにコピーされました。");
    },
    onError() {
      alert("リンクのコピーに失敗しました。");
    },
    share(article) {
      this.selectedArticle = article;
      this.drawer = !this.drawer;
    },
    dialogResponse() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
a {
  color: #000;
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}

section {
  margin: 0 auto;
}

#home {
  text-align: center;
}

#tab1 {
  background-color: white;
  /* z-index: 4; */
}

#tab2 {
  background-color: white;
  /* z-index: 3; */
}

#tab3 {
  background-color: white;
  /* z-index: 2; */
}

#tab4 {
  background-color: white;
  /* z-index: 1; */
}

#tab1 .button {
  background: #ea554e;
  color: #fff;
}

#tab2 .button {
  background: #0089aa;
  color: #fff;
}

#tab3 .button {
  background: #25945b;
  color: #fff;
}

#tab4 .button {
  background: #595757;
  color: #fff;
}

.header {
  width: 100%;
  background-color: white;
}

.header h1 {
  width: 250px;
  margin: 0 auto;
  font-size: 16px;
  display: contents;
}

.header h1 a {
  display: block;
  margin-bottom: -10px;
}

.header-fixed {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 6;
}

.header-fixed h1 {
  margin: 0 auto;
  display: contents;
}

.header-fixed img {
  margin-top: 8px;
  padding: 0 8px 0 8px;
  width: 100%;
  max-width: 768px;
}

.tab-content {
  /* position: absolute; */
  width: 100vw;
  overflow-x: hidden;
  transform: translateZ(0);
  pointer-events: auto;
}

.tab-inner {
  width: 100vw;
  max-width: 768px;
  margin: 0 auto;
}

.button {
  display: block;
  text-align: center;
  color: #fff;
  padding: 2px 8px;
  width: 100%;
  border-radius: 4px;
}

.page-line {
  position: fixed;
  background-color: #666;
  width: 30px;
  height: 100%;
  top: 0;
  z-index: 20000;
  visibility: hidden;
}

.main-tab-menu.news-tab-menu {
  background: #ea554e;
  width: 100%;
}

.main-tab-menu-inner {
  display: flex;
  justify-content: space-around;
  padding: 10px 6px 0 6px;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.main-tab-menu-item {
  font-weight: bold;
  background: #ea554e;
  color: #fff;
  flex: 1;
  text-align: center;
  border-radius: 4px 4px 0 0;
  margin-top: -4px;
  padding-top: 4px;
}

.main-tab-menu-item.active {
  background: #fff;
  color: #ea554e;
}

.main-tab-title {
  height: 34px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#event-tab.main-tab-title {
  background: #0089aa;
}

#link-tab.main-tab-title {
  background: #25945b;
}

#menu-tab.main-tab-title {
  background: #595757;
}

.main-tab-title-inner {
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.export-item {
  display: flex;
}
</style>
