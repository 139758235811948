<template>
  <div class="tab-inner">
    <div class="links" :class="active ? 'links-active' : ''">
      <div class="links-row-col-1">
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: {
                category: '海士町役場オフィシャル',
                links: links['official'],
              },
            })
          "
          class="link-btn-lg"
        >
          <div class="link-btn-lg-icon">海士</div>
          <div class="link-btn-lg-text">
            海士町役場<br />
            オフィシャルサイト一覧
          </div>
        </a>
      </div>
      <!--
      <div class="links-row-col-1">
        <a v-on:click="$router.push({ name: 'ip', params: { ips: links['ip'], notDirect: true } })" class="link-btn-lg">
          <div class="link-btn-lg-icon2">
            あま<br>ｃｈ
          </div>
          <div class="link-btn-lg-text">
            あまチャンネル<br>
            静止画告知情報
          </div>
        </a>
      </div>
      -->
      <div class="links-row-col-3">
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: '観光', links: links['sightseeing'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon"><div>観光</div></div></a
        >
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: '産業', links: links['industry'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon"><div>産業</div></div></a
        >
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: '教育', links: links['education'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon"><div>教育</div></div></a
        >
      </div>
      <div class="links-row-col-3">
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: '福祉', links: links['welfare'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon"><div>福祉</div></div></a
        >
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: '商工', links: links['business'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon"><div>商工</div></div></a
        >
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: '文化', links: links['culture'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon"><div>文化</div></div></a
        >
      </div>
      <div class="links-row-col-3">
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: '外部リンク', links: links['external'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon2">
            <div>外部<br />リンク</div>
          </div></a
        >
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: '隠岐', links: links['oki'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon"><div>隠岐</div></div></a
        >
        <a
          v-on:click="
            $router.push({
              name: 'link-detail',
              params: { category: 'アクセス', links: links['access'] },
            })
          "
          class="link-btn-sm"
          ><div class="link-btn-sm-icon2">
            <div>アク<br />セス</div>
          </div></a
        >
      </div>
      <!--
      <div class="links-row-col-1">
        <a href="http://www.town.ama.shimane.jp/" target="_blank" rel="noopener noreferrer" class="link-btn-lg">
          <div class="link-btn-lg-icon">
            な
          </div>
          <div class="link-btn-lg-text">
          ないものはない<br>
          ページ
          </div>
        </a>
      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["links", "active"],
  name: "link-view",
  data() {
    return {};
  },
  computed: {},

  methods: {
    trackOutboundLink(url, title) {
      this.$gtag.event("click", {
        event_category: "links_external_link",
        event_label: title,
        value: 1,
      });
    },
  },
};
</script>

<style scoped>
.button {
  display: block;
  text-align: center;
  color: #fff;
  padding: 2px 8px;
  width: 100%;
  border-radius: 4px;
  background: #25945b;
}

/*** リンク ***/
.links {
  width: 300px;
  margin: 30px auto;
  text-align: left;
}

.links-row-col-1,
.links-row-col-2,
.links-row-col-3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.links-space {
  flex: 1;
}

.link-btn-lg,
.link-btn-sm {
  pointer-events: none;
}

.links-active .link-btn-lg,
.links-active .link-btn-sm {
  pointer-events: auto;
}

.link-btn-lg {
  border: solid 4px #25945b;
  width: 100%;
  display: flex;
  overflow: hidden;
  height: 70px;
}

.link-btn-lg-icon {
  border: solid 2px #25945b;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #25945b;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: -4px;
  margin-left: -4px;
}

.link-btn-lg-icon2 {
  border: solid 2px #25945b;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  line-height: 20px;
  padding-top: 15px;
  background-color: #25945b;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: -4px;
  margin-left: -4px;
}

.link-btn-lg-text {
  color: #25945b;
  font-size: 18px;
  font-weight: bold;
  margin-top: 13px;
  margin-left: 8px;
  line-height: 20px;
}

.link-btn-sm {
  border: solid 4px #25945b;
  width: 70px;
  height: 70px;
  display: flex;
  overflow: hidden;
}

.link-btn-sm-icon {
  border: solid 2px #25945b;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #25945b;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: -4px;
  margin-left: -4px;
  margin-right: -4px;
}

.link-btn-sm-icon2 {
  border: solid 2px #25945b;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  line-height: 20px;
  padding-top: 15px;
  background-color: #25945b;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-top: -4px;
  margin-left: -4px;
  margin-right: -4px;
}
</style>
