<template>
  <v-bottom-navigation app
    v-model="selectedTab"
    color="teal"
    class="elevation-0"
    height="calc(env(safe-area-inset-bottom) + 56px)"
    grow
  >
    <v-btn class="nav-btn-top" value='top' :color="(selectedTab === 'top') ? '#EA554E' : '#FFF'" >
      <span>トップ</span>
      <v-icon :color="(selectedTab === 'top') ? '#FFF' : '#EA554E'">icon-top</v-icon>
    </v-btn>
    <v-btn class="nav-btn-event" value='event' :color="(selectedTab === 'event') ? '#0089AA' : '#FFF'">
      <span>イベント</span>
      <v-icon :color="(selectedTab === 'event') ? '#FFF' : '#0089AA'">icon-event</v-icon>
    </v-btn>
    <v-btn class="nav-btn-link" value='link' :color="(selectedTab === 'link') ? '#25945B' : '#FFF'">
      <span>リンク</span>
      <v-icon :color="(selectedTab === 'link') ? '#FFF' : '#25945B'">icon-link</v-icon>
    </v-btn>
    <v-btn class="nav-btn-menu" value='menu' :color="(selectedTab === 'menu') ? '#595757' : '#FFF'">
      <span>メニュー</span>
      <v-icon :color="(selectedTab === 'menu') ? '#FFF' : '#595757'">icon-menu</v-icon>
    </v-btn>
    <v-btn class="nav-btn-post" value='post'></v-btn>
  </v-bottom-navigation>
</template>

<script>

export default {
  props: ['currentTab'],
  created: function() {
    this.selectedTab = this.currentTab;
  },
  watch: {
    currentTab: function(newTab){
      if (newTab !== this.selectedTab) {
        this.selectedTab = newTab;
      }
    },
    selectedTab: function(newTab) {
      this.$emit("change-tab", newTab);
    }
  },
  data() {
    return {
      selectedTab: '',
    }
  },
  components: {
  },
  methods: {
  },
}
</script>

<style scoped>
.v-application .v-bottom-navigation {
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  border-top: #D4A900 6px solid;
  border-bottom: #D4A900 6px solid;
}

.v-application .v-bottom-navigation::after {
  content: "";
  padding-bottom: constant(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-top);
}

button.nav-btn-top span {
  color: #EA554E;
}
button.nav-btn-event span {
  color: #0089AA;
}
button.nav-btn-link span {
  color: #25945B;
}
button.nav-btn-menu span {
  color: #595757;
}
button.nav-btn-post {
  display: none;
}

button.v-btn--active span {
  color: #fff;
  font-weight: bold;
}

button.v-btn span {
  font-weight: bold;
}

button i:before {
  font-size: 22px;
  line-height: 22px;
}

button.nav-btn-link i:before {
  font-size: 28px;
  line-height: 22px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 100%;
}

.theme--light.v-btn:hover:before {
  opacity: 0 !important;
}
</style>