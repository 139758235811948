<script>
export default {
  methods: {
    openInBrowser(url, category, label) {
      this.$gtag.event('click', {
        'event_category': category,
        'event_label': label,
        'value': 1
      });
      window.open(url, "_blank", "noopener,noreferrer");
    },
  }
}
</script>