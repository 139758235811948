<template>
  <div class="list-item">
    <div class="thumb" v-if="article.image">
      <span v-if="!article.content" :href="article.url" v-on:click="trackOutboundLink(article.url, article.title, article.id)"><img v-lazy="article.image"></span>
    </div>
    <div class="text">
      <h4 class="posts-list-item-title">
        <span
          :href="article.url"
          v-on:click="trackOutboundLink(article.url, article.title, article.id)">
          {{ article.title }}
        </span>
      </h4>
      <div class="footer">
        <div class="source">
          <span class="link"
            v-if="article.source_url"
            :href="article.source_url"
            v-on:click="trackOutboundLink(article.url, article.title, article.id)">
            {{ article.source }}
          </span>
          <span v-else>{{ article.source }}</span>
        </div>
        <div v-if="article.tags" class="tags">
          <span v-for='(tag, index) in article.tags.split(",")' v-on:click="selectTag(tag)" :key="index">
            #{{ tag }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['article', 'isTopic'],
  name: 'featuredListItem',
  components: {},
  data() {
    return {
    }
  },
  methods: {
    trackOutboundLink(url, title, id) {
      this.$gtag.event('click', {
        'event_category': 'featured_external_link',
        'event_label': title,
        'value': 1
      });
      
      this.axios.get(process.env.VUE_APP_API_BASE_URL + 'featured_post_view/' + id);
      window.open(url, "_blank", "noopener,noreferrer");
    },
    selectTag(tag) {
      this.$emit('select-tag', tag);
    }
  },
}
</script>

<style scoped>
.list-item {
  width: 100%;
  display: flex;
}

.thumb {
  flex: 1;
}

.thumb img {
  width: 100%;
  max-height: 120px;
  height: 30vw;
  object-fit: cover;
}

.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}

.posts-list-item-title a {
  color: #2c3e50;
  pointer-events: auto;
}

.footer {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.footer .source {
  font-size: 12px;
}

.footer .tags {
  font-size: 12px;
  text-align: left;
}

.link {
  color: #1976d2;
}
</style>
