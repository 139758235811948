import { render, staticRenderFns } from "./BottomNavigation.vue?vue&type=template&id=81221a1c&scoped=true&"
import script from "./BottomNavigation.vue?vue&type=script&lang=js&"
export * from "./BottomNavigation.vue?vue&type=script&lang=js&"
import style0 from "./BottomNavigation.vue?vue&type=style&index=0&id=81221a1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81221a1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBottomNavigation,VBtn,VIcon})
