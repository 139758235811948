<template>
  <div class="tab-inner">
    <div class="main-tab-title">
      <div class="main-tab-title-inner">ニュースを投稿</div>
    </div>
    <div class="article-content">
      <p>海士町に関する島内外のイベントやニュース等を、あまとめに投稿することができます。下記のフォームから、必要事項を入力ください。</p>
      <h3>注意事項</h3>
      <p>
        あまとめは、海士町に関するあらゆる情報の発信と交流促進を目的としています。目的に沿わないコンテンツは掲載いたしかねますので、あらかじめご了承ください。
      </p>
      <h3>掲載基準</h3>
      <ul class="post-standard mb-4">
        <li>公序良俗に反しないもの</li>
        <li>アダルトコンテンツを含まないもの</li>
        <li>海士町とは関係のない特定の商品や思想等の宣伝を目的としていないもの</li>
        <li>犯罪行為に結びつくもの、又は違法な内容を含まないもの</li>
        <li>第三者の財産・プライバシーを侵害する内容、又は第三者への誹謗中傷を含まないもの</li>
        <li>選挙の事前運動、選挙運動又はこれらに類似するものや公職選挙法に抵触する内容を含まないもの</li>
        <li>不正アクセスやシステム停止等誤作動を引き起こす内容を含まないもの</li>
        <li>個人情報の取り扱いについて、十分配慮されているもの</li>
        <li>町民生活や「あまとめ」利用者に有益と認められるもの又は海士町役場が必要と認めたもの</li>
      </ul>
      <h3 class="input-form-title mb-4">入力フォーム</h3>
      <v-form ref="form" v-model="valid" class="text-center" enctype="multipart/form-data">
        <v-select v-model="requestType" :items="requestTypes" label="投稿の種類を選択してください" outlined></v-select>

        <v-card v-if="requestType !== ''" outlined>
          <v-card-title>
            連絡先情報(非公開)
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="author" :rules="[rules.required, rules.varchar]" persistent-hint
              hint="連絡用のお名前です。記事上に名前は公開されません。" label="お名前(必須)" prepend-icon="mdi-account" outlined
              required></v-text-field>
            <v-text-field v-model="email" :rules="[rules.required, rules.emailRules, rules.varchar]" persistent-hint
              hint="掲載の可否が配信されます。" label="連絡先メールアドレス(必須)" prepend-icon="mdi-mail" outlined required></v-text-field>
          </v-card-text>
        </v-card>
        <v-card v-if="requestType !== 2" class="mt-4" outlined>
          <v-card-title>
            記事内容(公開)
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="orgName" persistent-hint hint="記事の配信元として表示される名称です。団体名等を入力してください。"
              :rules="[rules.required, rules.varchar]" label="配信元名(必須)" prepend-icon="mdi-domain" required
              outlined></v-text-field>
            <v-text-field v-model="postTitle" :rules="[rules.required, rules.varchar]" label="タイトル(必須)"
              prepend-icon="mdi-format-title" outlined required></v-text-field>
            <v-textarea v-model="postBody" :rules="[rules.required, rules.text]" label="本文(必須)"
              prepend-icon="mdi-text-box-outline" outlined required></v-textarea>
            <v-file-input v-model="postImage" @change="selectFile" accept="image/*" label="添付画像(任意)"
              prepend-icon="mdi-image" outlined></v-file-input>
            <v-menu v-model="preferredDatePicker" max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field slot="activator" v-model="preferredDate" persistent-hint hint="入力がない場合は審査が完了次第配信されます。"
                  label="公開希望日(任意)" prepend-icon="mdi-calendar" outlined readonly v-on="on" />
              </template>
              <v-date-picker v-model="preferredDate" />
            </v-menu>
          </v-card-text>
        </v-card>

        <v-card v-if="requestType === 2" class="mt-4" outlined>
          <v-card-title>
            記事リンク(公開)
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="postLink" :rules="[rules.required, rules.urlFormat, rules.text]" label="リンク先URL(必須)"
              prepend-icon="mdi-text-box-outline" outlined required></v-text-field>
          </v-card-text>
        </v-card>

        <v-card v-if="requestType === 1" class="mt-4" outlined>
          <v-card-title>
            イベント情報(公開)
          </v-card-title>
          <v-card-text>
            <v-btn class="mb-4" color="primary" @click="onTapCopy()">
              記事タイトルと本文を転記する<v-icon>mdi-arrow-down-bold-circle-outline</v-icon>
            </v-btn>
            <v-text-field v-model="eventTitle" :rules="[rules.required, rules.varchar]" label="イベントのタイトル(必須)"
              prepend-icon="mdi-format-title" outlined required></v-text-field>
            <v-textarea v-model="eventBody" :rules="[rules.text]" persistent-hint label="イベントの概要(任意)"
              prepend-icon="mdi-text-box-outline" outlined></v-textarea>
            <v-text-field v-model="eventPlace" :rules="[rules.varchar]" label="開催場所(任意)" prepend-icon="mdi-map-marker"
              outlined></v-text-field>
            <v-menu v-model="startDatePicker" max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field slot="activator" v-model="startDate" :rules="[rules.required]" label="イベント開始日(必須)"
                  prepend-icon="mdi-arrow-expand-right" outlined readonly v-on="on" />
              </template>
              <v-date-picker v-model="startDate" />
            </v-menu>
            <v-menu v-model="endDatePicker" max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field slot="activator" v-model="endDate" persistent-hint
                  hint="土日イベント等、イベントが1日で終わらない場合は終了日を入力ください。※1日のみのイベントの場合は、入力不要です。" :rules="[rules.endDate]"
                  label="イベント終了日(任意)" prepend-icon="mdi-arrow-expand-left" outlined clearable readonly v-on="on" />
              </template>
              <v-date-picker v-model="endDate" />
            </v-menu>
          </v-card-text>
        </v-card>

        <v-textarea v-if="requestType !== ''" class="mt-4" v-model="message" :rules="[rules.text]"
          hint="運営に伝えたい補足事項がある場合はここに入力してください。" label="補足事項(非公開)" prepend-icon="mdi-message-text-outline" outlined
          required></v-textarea>

        <v-btn v-if="requestType !== ''" block :disabled="!valid || sending" :loading="sending" color="success"
          @click="onTapSend()">
          確認画面へ
        </v-btn>
      </v-form>

      <v-dialog v-model="showConfirmDialog" max-width="425px" hide-overlay fullscreen
        transition="dialog-bottom-transition">
        <v-card id="confirm-dialog-inner" outlined>
          <v-card-title>リクエスト送信内容の確認</v-card-title>
          <!--
          <v-toolbar dense>
            <v-btn icon @click="showConfirmDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>送信内容の確認</v-toolbar-title>
          </v-toolbar>
          -->
          <v-card-text>下記の内容で間違いがなければ、送信ボタンを押してください。</v-card-text>
          <v-divider></v-divider>
          <div>
            <v-subheader>連絡先情報(非公開)</v-subheader>
            <v-card class="ml-4 mr-4" outlined>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>お名前</th>
                    <td>{{ author }}</td>
                  </tr>
                  <tr>
                    <th>連絡先メールアドレス</th>
                    <td>{{ email }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
          <div v-if="requestType !== 2">
            <v-subheader>投稿記事(公開)</v-subheader>
            <v-card class="ml-4 mr-4" outlined>
              <v-card-title>{{ postTitle }}</v-card-title>
              <v-img v-if="imageContent" :src="imageContent" contain></v-img>
              <v-card-text v-if="postBody">
                {{ postBody }}
              </v-card-text>
              <v-card-text v-if="postLink">
                {{ postLink }}
              </v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>配信元名</th>
                    <td>{{ orgName }}</td>
                  </tr>
                  <tr>
                    <th>公開希望日</th>
                    <td>{{ preferredDate }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
          <div v-if="requestType === 2">
            <v-subheader>記事リンク(公開)</v-subheader>
            <v-card class="ml-4 mr-4" outlined>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>記事URL</th>
                    <td>{{ postLink }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
          <div v-if="requestType === 1">
            <v-subheader>イベント(公開)</v-subheader>
            <v-card class="ml-4 mr-4" outlined>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>イベントタイトル</th>
                    <td>{{ eventTitle }}</td>
                  </tr>
                  <tr v-if="eventPlace">
                    <th>開催場所</th>
                    <td>{{ eventPlace }}</td>
                  </tr>
                  <tr v-if="eventBody">
                    <th colspan="2">イベントの概要</th>
                  </tr>
                  <tr v-if="eventBody">
                    <td colspan="2">{{ eventBody }}</td>
                  </tr>
                  <tr>
                    <th>イベント開始日</th>
                    <td>{{ startDate }}</td>
                  </tr>
                  <tr v-if="endDate">
                    <th>イベント終了日</th>
                    <td>{{ endDate }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
          <div>
            <v-subheader>その他の情報(非公開)</v-subheader>
            <v-card class="ml-4 mr-4" outlined>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>補足事項</th>
                  </tr>
                  <tr>
                    <td>{{ message }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
          <v-container class="text-center">
            <v-btn class="ma-2" @click="showConfirmDialog = false">
              戻る
            </v-btn>
            <v-btn class="ma-2" color="success" @click="submit">
              送信
            </v-btn>
          </v-container>
        </v-card>
      </v-dialog>
      <v-alert v-model="showSendSuccess" prominent outlined type="success" class="mt-4">
        <v-row dense align="center">
          <v-col>掲載リクエストの送信が完了しました。掲載まで数日かかる場合がありますのでご了承ください。内容が不適と判断された場合は後日メールで通知されます。</v-col>
        </v-row>
      </v-alert>
      <v-alert v-model="showSendError" prominent type="error">
        <v-row>
          <v-col>掲載リクエストの送信に失敗しました。通信環境などをご確認の上、再度の送信をお願いいたします。</v-col>
        </v-row>
      </v-alert>
    </div>
    <!--
    <div class="article-footer">
      <a v-if="$route.params.notDirect" @click="$router.go(-1)"><font-awesome-icon icon="chevron-left" /> ホームに戻る</a>
      <a v-else href="/"><font-awesome-icon icon="chevron-left" /> ホームに戻る</a>
    </div>
    -->
    <div class="recaptcha-text my-2">
      This site is protected by reCAPTCHA and the Google<br>
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY })

export default {
  props: ['menus'],
  components: {
  },
  name: 'RequestForm',
  data() {
    return {
      drawer: null,
      valid: true,
      requestTypes: [{ 'text': 'ニュースのみ', 'value': 0 },
      { 'text': 'ニュースとイベント', 'value': 1 },
      { 'text': '記事のリンク', 'value': 2 }],
      requestType: '',
      author: '',
      orgName: '',
      email: '',
      postTitle: '',
      postBody: '',
      postLink: '',
      postImage: null,
      imageContent: null,
      eventTitle: '',
      eventPlace: '',
      eventBody: '',
      preferredDate: '',
      preferredDatePicker: null,
      startDate: '',
      startDatePicker: null,
      endDate: null,
      endDatePicker: null,
      message: '',
      token: '',
      errorMessage: '',
      showConfirmDialog: false,
      showSendError: false,
      showSendSuccess: false,
      sending: false,
      rules: {
        required: (v) => !!v || '必須項目です',
        emailRules: (v) => {
          return /.+@.+/.test(v) || 'メールアドレスの形式が正しくありません';
        },
        urlFormat: (v) => {
          return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(v) || 'URL の形式が正しくありません';
        },
        varchar: (v) => {
          return v ? v.length <= 191 || "191文字以内で入力してください" : true;
        },
        text: (v) => {
          return v ? v.length <= 5000 || "5000文字以内で入力してください" : true;
        },
        endDate: (v) => {
          return v ? v > this.startDate || "開始日よりあとの日付を入力してください" : true;
        }
      },
    }
  },
  computed: {
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "post".
      const token = await this.$recaptcha('post')
      const result = this.$refs.form.validate();
      if (result) {
        this.postRequest(token);
      } else {
        //console.log("");
      }
    },
    selectFile(event) {
      this.postImage = event;
    },
    postRequest(token) {
      let formData = new FormData()
      formData.append('type', this.requestType);
      formData.append('author', this.author);
      formData.append('source', this.orgName);
      formData.append('mail', this.email);
      formData.append('post_title', this.postTitle);
      formData.append('post_body', this.postBody);
      formData.append('post_link', this.postLink);
      formData.append('image', this.postImage);
      formData.append('event_title', this.eventTitle);
      formData.append('event_place', this.eventPlace);
      formData.append('event_body', this.eventBody);
      formData.append('preferred_date', this.preferredDate);
      formData.append('start_date', this.startDate);
      formData.append('end_date', this.endDate);
      formData.append('message', this.message);
      formData.append('token', token);

      this.axios.post(
        process.env.VUE_APP_API_BASE_URL + 'post_request',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(( /* res */) => {
        //console.log(res);
        this.sending = false;
        this.showSendSuccess = true;
        this.$refs.form.reset();
        this.valid = false;
      }).catch(function ( /* error */) {
        //console.log(error);
        this.sending = false;
        this.errorMessage = '送信エラーが発生しました。'
        this.showSendError = true
      })
    },
    onTapSend() {
      const result = this.$refs.form.validate();
      if (result) {
        if (this.postImage) {
          var reader = new FileReader();
          //console.log(reader);
          reader.readAsDataURL(this.postImage);
          reader.onload = () => {
            this.imageContent = reader.result;
            //console.log(this.imageContent);
            this.showConfirmDialog = true;
          }
        } else {
          //console.log(this.postImage);
          this.imageContent = null;
          this.showConfirmDialog = true;
        }
      }
    },
    onTapCopy() {
      this.eventTitle = this.postTitle;
      this.eventBody = this.postBody;
    },
    submit(e) {
      e.preventDefault();
      this.sending = true;
      this.showConfirmDialog = false;
      this.recaptcha();
    },
  },
  created() {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3.input-form-title {
  background: #d0a638;
  color: #fff;
  text-align: center;
}

.main-tab-title {
  background: #d0a638;
  height: 34px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-tab-title-inner {
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.tab-inner {
  text-align: left;
}

.article-content {
  margin: 0 10px;
}

.post-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.post-standard {
  font-size: 0.75rem;
  list-style-type: disc;
}

.article-footer {
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
  max-width: 768px;
}

#confirm-dialog-inner {
  min-width: 0;
  margin: 0 auto !important;
}

.recaptcha-text {
  text-align: center;
  font-size: 12px;
}
</style>

