import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home';

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/article/:guid',
      name: 'article',
      component: () =>
        import(/* webpackChunkName: "article" */ "./components/Article.vue")
    },
    {
      path: '/link-detail',
      name: 'link-detail',
      component: () =>
        import(/* webpackChunkName: "link-detail" */ "./components/links/LinkDetail.vue")
    },
    {
      path: '/pages/:slug',
      name: 'pages',
      component: () =>
        import(/* webpackChunkName: "page" */ "./components/Page.vue")
    },
    {
      path: '/ip',
      redirect: { name: 'home' }
    },
    {
      path: '/request',
      name: 'request',
      component: () =>
        import(/* webpackChunkName: "request" */ "./views/RequestForm.vue")
    },
    {
      path: '/inquiry',
      name: 'inquiry',
      component: () =>
        import(/* webpackChunkName: "inquiry" */ "./views/InquiryForm.vue")
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
