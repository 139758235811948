<template>
  <v-dialog
      v-model="dialog"
      width="95vw"
      @input="onOpenDialog()"
    >
    <template v-slot:activator="{ on, attrs }">
      <div class="ip-thumb">
      <a v-bind="attrs" v-on="on">
        <img v-lazy="ip.image">
      </a>
      </div>
      <div class="ip-text">
        <h4 class="ip-title">
          <a v-bind="attrs" v-on="on">{{ ip.title }}</a>
        </h4>
      </div>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ ip.title }}
      </v-card-title>
      <div><img class="ip-modal-img" v-lazy="ip.image"></div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          outlined
          @click="dialog = false"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['ip'],
  components: {},
  name: 'IpListItem',
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
  },
  methods: {
    onOpenDialog() {
      this.$gtag.pageview({
        page_path: this.$route.path + "?tab=top&news_type=ip",
        page_title: this.ip.title
      });
    },
  },
  filters: {
  }
}
</script>

<style scoped>

.ip-item {
  width: 100%;
  display: flex;
}

.ip-list-item {
  display:flex;
  flex-direction: column;
}

.ip-thumb {
  flex: 1;
}

.ip-thumb img {
  width: 100%;
  object-fit: cover;
}

.ip-title {
  font-size: 12px;
}

.ip-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ip-modal-img {
  width: 100%;
}

.v-dialog {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  margin: 0;
}
</style>
