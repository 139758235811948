<template>
  <div class="tab-inner">
    <v-list id="menu-list">
      <v-list-item v-for="item in visibleMenus" :key="item.title" link>
        <v-list-item-icon v-if="item.order !== 0">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content v-if="item.order !== 0">
          <v-list-item-title>
            <a
              v-if="item.link"
              v-on:click="trackOutboundLink(item.link, item.title)"
              >{{ item.title }}</a
            >
            <router-link
              v-if="!item.link"
              class="export-item"
              v-on:click="trackOutboundLink(item.slug, item.title)"
              :to="{
                name: 'pages',
                params: { slug: item.slug, notDirect: true },
              }"
              >{{ item.title }}</router-link
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  props: ["active", "menus"],
  name: "menu-view",
  data() {
    return {};
  },
  computed: {
    visibleMenus() {
      return this.menus.filter((item) => item.order !== 0);
    },
  },
  methods: {
    trackOutboundLink(url, title) {
      this.$gtag.event("click", {
        event_category: "links_external_link",
        event_label: title,
        value: 1,
      });
      window.open(url, "_blank", "noopener,noreferrer");
    },
  },
};
</script>

<style scoped>
.button {
  display: block;
  text-align: center;
  color: #fff;
  padding: 2px 8px;
  width: 100%;
  border-radius: 4px;
  background: #595757;
}

#menu-list {
  text-align: left;
  max-width: 768px;
  margin: 0 auto;
}
</style>
