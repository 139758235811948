<template>
  <v-dialog :value="visibleDialog" max-width="425px">
    <v-card v-show="sending">
      <vue-loading
        type="spiningDubbles"
        :size="{ width: '70px', height: '70px' }"
      ></vue-loading>
    </v-card>
    <v-card v-show="!sending">
      <v-card-title>
        <span class="headline">記事の問題を報告</span>
      </v-card-title>

      <v-card-text>
        この記事の内容に問題があると思われる場合、あまとめ運営に連絡することができます。
      </v-card-text>
      <v-container fluid>
        <v-form ref="form" enctype="multipart/form-data">
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                name="probrem"
                label="問題と思われる内容"
                v-model="message"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('result')">
          キャンセル
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit"> 送信 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueLoading } from "vue-loading-template";

export default {
  props: ["visibleDialog", "article"],
  components: { VueLoading },
  name: "problemReportDialog",
  data() {
    return {
      message: "",
      errorMessage: "",
      sending: false,
    };
  },
  methods: {
    reportProblem() {
      let formData = new FormData();
      formData.append("guid", this.article.guid);
      formData.append("message", this.message);
      this.axios
        .post(process.env.VUE_APP_API_BASE_URL + "problem_report", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((/* res */) => {
          //console.log(res);
          this.valid = false;
          this.$emit("result");
          this.sending = false;
          alert("送信が完了しました。");
        })
        .catch(function (/* error */) {
          alert("送信エラーが発生しました。通信状況を確認してください。");
          this.$emit("result");
          this.sending = false;
        });
    },
    submit(e) {
      e.preventDefault();
      this.sending = true;
      const result = this.$refs.form.validate();
      if (result) {
        this.reportProblem();
      }
    },
  },
};
</script>
