<template>
  <h4 class="posts-list-item-title">
    <span
      v-if="!article.content"
      :href="article.url"
      v-on:click="openInBrowser(article.url, eventCategory, article.title)"
    >
      {{ article.title | omittedText }}
    </span>
    <div v-else @click="linkToArticle(article.guid)">
      {{ article.title | omittedText }}
    </div>
  </h4>
</template>

<script>
export default {
  props: ["article", "eventCategory"],
  name: "postsListItemTitle",
  data() {
    return {};
  },
  methods: {
    linkToArticle(guid) {
      this.axios.get(process.env.VUE_APP_API_BASE_URL + "article_view/" + guid);
      this.$router.push({
        name: "article",
        params: { guid: guid, notDirect: true },
      });
    },
  },
  filters: {
    omittedText(text) {
      return text.length > 39 ? text.slice(0, 39) + "…" : text;
    },
  },
};
</script>

<style scoped>
.posts-list-item-title a {
  color: #2c3e50;
  pointer-events: auto;
}
</style>
