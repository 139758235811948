import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Meta from 'vue-meta'
import VueGtag from "vue-gtag"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vuetify from './plugins/vuetify'
import VueLazyload from 'vue-lazyload'
import OpenInBrowserMixin from './mixins/OpenInBrowser'
import { Capacitor } from '@capacitor/core'

if (!Capacitor.isNativePlatform()) {
  !function (t, e, n, s, a, c, i, o, p) { t.AppsFlyerSdkObject = a, t.AF = t.AF || function () { (t.AF.q = t.AF.q || []).push([Date.now()].concat(Array.prototype.slice.call(arguments))) }, t.AF.id = t.AF.id || i, t.AF.plugins = {}, o = e.createElement(n), p = e.getElementsByTagName(n)[0], o.async = 1, o.src = "https://websdk.appsflyer.com?" + (c.length > 0 ? "st=" + c.split(",").sort().join(",") + "&" : "") + (i.length > 0 ? "af_id=" + i : ""), p.parentNode.insertBefore(o, p) }(window, document, "script", 0, "AF", "banners", { banners: { key: "db406bb1-40dd-487d-aefb-a793b185c820" } })
  AF('banners', 'showBanner') // eslint-disable-line  
}

Vue.use(Meta)
Vue.use(VueGtag, {
  config: {
    id: "UA-174172212-1",
    params: {
      send_page_view: false
    }
  },
});

library.add(faChevronLeft, faShareSquare)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'https://placehold.jp/24/b3b3b3/ffffff/190x120.png?text=Not%20found',
  loading: 'https://placehold.jp/24/b3b3b3/ffffff/190x120.png?text=Now%20loading',
  attempt: 1
})

Vue.mixin(OpenInBrowserMixin)

new Vue({
  router,
  vuetify,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
