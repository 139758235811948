<template>
  <PullRefresh :on-refresh="onRefresh">
    <div v-show="loading" class="loading-view">
      <vue-loading
        type="spiningDubbles"
        :size="{ width: '70px', height: '70px' }"
      ></vue-loading>
    </div>
    <v-tab-item v-if="!loading" value="news">
      <!-- 今日の海士町 -->
      <div id="today" class="news-list">
        <div class="news-list-inner">
          <Today :weather="weather" />
        </div>
      </div>
      <!-- トピック記事 -->
      <div id="topic-posts" v-show="!loading" class="news-list">
        <div v-if="topics.length > 0" class="news-list-row-col-1">
          <NewsListItem :article="topics[0]" :is-topic="true" @share="share" />
        </div>
        <div v-if="topics.length === 2" class="news-list-row-col-1">
          <NewsListItem :article="topics[1]" :is-topic="true" @share="share" />
        </div>
        <div v-if="topics.length > 2" class="news-list-row-col-2">
          <NewsListItem :article="topics[1]" :is-topic="false" @share="share" />
          <NewsListItem :article="topics[2]" :is-topic="false" @share="share" />
        </div>
      </div>
      <!-- 通常記事 -->
      <div id="normal-posts" class="news-list">
        <div class="news-list-inner">
          <div
            class="news-list-item-wrapper"
            v-for="(article, index) in normalPosts"
            :key="index"
          >
            <NewsListItem :article="article" :is-topic="false" @share="share" />
          </div>
        </div>
      </div>
    </v-tab-item>
    <v-tab-item v-if="!loading" value="ip">
      <v-card class="ma-2" outlined>
        <v-card-text>
          <div class="text-justify">
            海士町のケーブルテレビあまchで放送されている島内向け静止画告知です。
          </div>
        </v-card-text>
      </v-card>
      <div class="ip-list">
        <div
          class="ip-list-item-wrapper"
          v-for="(ip, index) in ipLinks"
          :key="index"
        >
          <IpListItem :ip="ip" />
        </div>
      </div>
    </v-tab-item>
    <v-tab-item v-if="!loading" class="featured-list" value="featured">
      <div class="featured-list-order">
        <v-btn-toggle v-model="featuredPostsOrder" mandatory>
          <v-btn value="newest">
            <v-icon>mdi-new-box</v-icon>
            <span>新着順</span>
          </v-btn>
          <v-btn value="random">
            <v-icon>mdi-shuffle-variant</v-icon>
            <span>ランダム</span>
          </v-btn>
          <v-btn value="popularity">
            <v-icon>mdi-star</v-icon>
            <span>人気順</span>
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="featured-tags-list">
        <h4>タグで絞り込む</h4>
        <span
          v-on:click="selectTag(tag.name)"
          class="featured-tag"
          :class="featuredTagClass(tag)"
          v-for="(tag, index) in filterdTags"
          :key="index"
          >#{{ tag.name }}</span
        >
        <span v-on:click="expandTags(5)" class="featured-tags-more"
          >もっと見る</span
        >
      </div>
      <div class="featured-list-inner">
        <div
          class="featured-list-item-wrapper"
          v-for="(article, index) in filterdFeaturedPosts"
          :key="index"
        >
          <FeaturedListItem
            :article="article"
            :is-topic="false"
            @select-tag="selectTag"
          />
        </div>
      </div>
    </v-tab-item>
  </PullRefresh>
</template>

<script>
import Today from "./Today";
import NewsListItem from "./NewsListItem";
import FeaturedListItem from "./FeaturedListItem";
import IpListItem from "../links/ip/IpListItem";
import PullRefresh from "../ui/PullRefresh";
import { VueLoading } from "vue-loading-template";
import dayjs from "dayjs";

export default {
  props: [
    "active",
    "loading",
    "currentNewsTab",
    "topics",
    "rawPosts",
    "featuredPosts",
    "tags",
    "weather",
    "links",
  ],
  components: {
    Today,
    NewsListItem,
    FeaturedListItem,
    IpListItem,
    VueLoading,
    PullRefresh,
  },
  name: "news-view",

  data() {
    return {
      selectedTag: null,
      maxVisibleTags: 5,
      featuredPostsOrder: "random",
    };
  },

  watch: {
    tags: function (newTags, oldTags) {
      if (this.selectedTag && oldTags.length === 0) {
        while (this.maxVisibleTags < newTags.length) {
          if (this.filterdTags.some((tag) => tag.name === this.selectedTag)) {
            break;
          } else {
            this.maxVisibleTags += 5;
          }
        }
      }
    },
    currentNewsTab: function (newTab) {
      this.$router
        .push({ path: "/", query: { tab: "top", news_type: newTab } })
        .catch(() => {});
    },
  },

  created() {
    let tagParam = this.$route.query.tag;
    if (this.currentNewsTab === "featured") {
      if (tagParam) {
        this.selectedTag = decodeURI(tagParam);
      } else {
        this.selectedTag = null;
      }
    }
  },

  computed: {
    normalPosts() {
      if (this.rawPosts) {
        return this.rawPosts;
      } else {
        return null;
      }
    },
    filterdFeaturedPosts() {
      let filterdList = this.featuredPosts.filter(function (post) {
        if (this.selectedTag) {
          return post.tags
            ? post.tags.split(",").includes(this.selectedTag)
            : false;
        } else {
          return true;
        }
      }, this);

      if (this.featuredPostsOrder === "newest") {
        return filterdList.sort((a, b) => {
          return dayjs(b.date).diff(dayjs(a.date));
        });
      } else if (this.featuredPostsOrder === "popularity") {
        return filterdList.sort((a, b) => {
          return b.view_count - a.view_count;
        });
      } /* if (this.featuredPostsOrder === "random") */ else {
        return filterdList;
      }
    },
    ipLinks() {
      return this.links["ip"];
    },
    filterdTags() {
      return this.tags.slice(0, this.maxVisibleTags);
    },
  },
  methods: {
    selectTag(tagName) {
      if (this.selectedTag === tagName) {
        this.selectedTag = null;
        this.$router
          .push({ path: "/", query: { tab: "top", news_type: "featured" } })
          .catch(() => {});
      } else {
        this.selectedTag = tagName;
        this.$router
          .push({
            path: "/",
            query: {
              tab: "top",
              news_type: "featured",
              tag: encodeURI(tagName),
            },
          })
          .catch(() => {});
      }
    },
    expandTags(num) {
      this.maxVisibleTags += num;
    },
    featuredTagClass(tag) {
      return { active: this.selectedTag === tag.name };
    },
    onRefresh() {
      return new Promise(
        function (resolve) {
          setTimeout(
            function () {
              this.$emit("reload");
              resolve();
            }.bind(this),
            3000
          );
        }.bind(this)
      );
    },
    share(article) {
      this.$emit("share", article);
    },
  },
};
</script>

<style>
a {
  color: #000;
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}

.loading-view {
  height: 100vh;
  padding-top: 30vh;
}

.loading-view .vue-loading {
  fill: #ea554e !important;
}

.button {
  display: block;
  text-align: center;
  color: #fff;
  padding: 2px 8px;
  width: 100%;
  border-radius: 4px;
  background: #ea554e;
}

.news-list .news-title a,
.featured-list .news-title a {
  color: #2c3e50;
  pointer-events: auto;
}

/*** News ***/
.news-list {
  margin: 0 10px;
  text-align: left;
  border-color: #b5b5b6;
}

#normal-posts {
  margin-top: 0;
}

#normal-posts .news-list-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

#normal-posts .news-list-inner:after {
  content: "";
  display: block;
  width: 33.33%;
  height: 0;
}

#topic-posts.news-list {
  border-bottom: solid 1px #b5b5b6;
}

.news-list-row-col-1,
.news-list-row-col-2,
.news-list-row-col-3 {
  display: flex;
  border-top: solid 1px #b5b5b6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.news-list-row-col-2,
.news-list-row-col-3 {
  justify-content: space-between;
}

.news-list-item-wrapper {
  border-bottom: solid 1px #b5b5b6;
  width: 33.33%;
}

@media screen and (max-width: 480px) {
  .news-list-item-wrapper {
    border-bottom: solid 1px #b5b5b6;
    width: 50%;
  }
}

/*** Featured ***/
.featured-tags-list {
  border-bottom: solid 1px #b5b5b6;
  padding: 10px 0;
}

.featured-tag {
  padding: 4px;
  display: inline-block;
}

.featured-tags-more {
  padding: 4px;
  display: inline-block;
}

.featured-tag.active {
  font-weight: bold;
}

.featured-list-order {
  display: flex;
  justify-content: center;
  padding: 20px 0 10px 0;
}

.featured-list-order .v-btn.v-size--default {
  font-size: 1rem;
}

.v-btn > .v-btn__content .v-icon {
  font-size: 1.5rem;
}

@media screen and (max-width: 350px) {
  .featured-list-order .v-btn > .v-btn__content .v-icon {
    display: none;
  }
}

.featured-list {
  text-align: left;
  margin: 0 10px;
}

.featured-list-item-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50%;
}

.featured-list-item-wrapper:nth-child(2n-1) {
  padding-right: 10px;
}

@media screen and (max-width: 480px) {
  .featured-list-item-wrapper {
    width: 100%;
  }

  .featured-list-item-wrapper:nth-child(2n-1) {
    padding-right: 0;
  }
}

.featured-list-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.featured-list-row-col-1 {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

/*** IP ***/
.ip-list {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.ip-list-item-wrapper {
  width: 32%;
  margin-right: 2%;
  margin-bottom: 10px;
}

.ip-list-item-wrapper:nth-child(3n) {
  margin-right: 0;
}

@media screen and (max-width: 480px) {
  .ip-list-item-wrapper {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 10px;
  }

  .ip-list-item-wrapper:nth-child(3n) {
    margin-right: 4%;
  }

  .ip-list-item-wrapper:nth-child(2n) {
    margin-right: 0;
  }
}

.featured-list-order .v-item-group button.v-btn {
  height: 36px !important;
}
</style>
