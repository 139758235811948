<template>
  <section id="today-ama">
    <div class="today-ama-inner">
      <div class="today-ama-title">
        <div>今日の海士町</div>
        <div class="today-ama-date">{{ todayString }}</div>
      </div>
      <div class="today-ama-content">
        <div>
          <div id="weather">
            天気：<img id="weather-icon" :src="weather.condition_icon" width="24px" height="24px" />
            <span id="temp">気温： <span id="max-temp">{{ weather.maxtemp_c }}℃</span> /
              <span id="min-temp">{{ weather.mintemp_c }}℃</span>
            </span>
          </div>
          <div id="weather2">
            <!-- <span id="wave">波：5m</span> -->
            <span id="wind-power">風：{{ weather.maxwind_mph }}m</span>
            <span id="wind-dir">風向：{{ weather.wind_dir }}</span>
          </div>
        </div>

        <a id="ship-status-btn" @click="
          trackOutboundLink(
            'https://naturebot-lab.com/ferry_transit/',
            '船の運行・波の高さ'
          )
        ">
          <img src="@/assets/ferry.png" alt="船の運航・波の高さ" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import 'dayjs/locale/ja';
import { jpFormat } from 'dayjs-jp-format'

dayjs.locale('ja');
dayjs.extend(jpFormat);

export default {
  props: ["weather"],
  name: "today-ama",
  data() {
    return {};
  },
  computed: {
    todayString() {
      return dayjs(Date.now()).format('rrrrM月D日(ddd)');
    },
  },
  methods: {
    trackOutboundLink(url, title) {
      this.$gtag.event("click", {
        event_category: "ship_external_link",
        event_label: title,
        value: 1,
      });
      window.open(url, "_blank", "noopener,noreferrer");
    },
  },
};
</script>

<style scoped>
a {
  color: #000;
  text-decoration: none;
  pointer-events: auto;
}

ul {
  list-style: none;
  padding-left: 0;
}

v-btn {
  pointer-events: auto;
}

.powered-by {
  text-align: right;
  font-weight: normal;
  font-size: 12px;
}

.button {
  display: block;
  text-align: center;
  color: #fff;
  padding: 2px 8px;
  width: 100%;
  border-radius: 4px;
  background: #ea554e;
}

#today-ama {
  color: #2c3e50;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.today-ama-inner {
  flex-grow: 1;
}

.today-ama-title {
  color: #ea554e;
  font-weight: bold;
  padding: 4px 0 4px 8px;
  border-bottom: 1px solid #b5b5b6;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.today-ama-date {
  font-size: 14px;
  color: #666;
}

.today-ama-content {
  font-size: 14px;
  width: 100%;
  padding: 4px 0 4px 8px;
  display: flex;
  justify-content: space-between;
}

#ship-status-btn {
  display: flex;
}

#ship-status-btn img {
  width: 100px;
}

#weather-icon {
  margin-bottom: -8px;
  margin-right: 8px;
}

#weather2 {
  display: flex;
}

#wind-dir {
  margin-left: 8px;
}

#max-temp {
  color: #ea554e;
}

#min-temp {
  color: #0089aa;
}
</style>
