<template>
  <div class="tab-inner" :class="active ? 'calendar-active' : ''">
    <div class="g-calendar">
      <iframe
        src="https://calendar.google.com/calendar/b/2/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Asia%2FTokyo&amp;src=dG93bi5hbWEuZXZlbnRzQGdtYWlsLmNvbQ&amp;color=%23FFFFFF&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;showDate=0&amp;showNav=0&amp;mode=AGENDA&amp;showTitle=0"
        width="800"
        height="600"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>

    <!--
    <div v-for='(month, index) in calendarContents' :key="index" class="calendar">
      <h2>{{month.name}}</h2>
      <div v-for="(day, dayIndex) in month.days" :key="dayIndex" class="calendar-row" :class="month.layout">
        <span class="calendar-date">{{dayIndex + 1}}</span>
        <ul v-for='(event, index) in day.events' :key="index" class="calendar-event">
          <li><span class="calendar-event-title"><a :href="event.link" target="_blank" rel="noopener noreferrer">{{ event.title }}</a></span> @{{ event.place }}</li>
        </ul>
      </div>
    </div>
    -->
  </div>
</template>

<script>
export default {
  props: ["active"],
  name: "calendar-view",
  data() {
    return {
      loading: true,
      calendarContents: [
        {
          name: "1月",
          days: [
            {
              events: [
                {
                  title: "隠岐神社例大祭",
                  link: "https://oki-ama.org/2019-10-16/",
                  place: "隠岐神社",
                },
                {
                  title: "キンニャモニャ祭り",
                  link: "https://oki-ama.org/",
                  place: "菱浦港",
                },
              ],
            },
            {},
            {},
            {},
            {},
            {
              events: [
                {
                  title: "食の感謝祭",
                  link: "https://oki-ama.org/2019-10-16/",
                  place: "隠岐神社",
                },
              ],
            },
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {
              events: [
                {
                  title: "ブータン来島",
                  link: "https://oki-ama.org/2019-10-16/",
                  place: "島前高校",
                },
              ],
            },
            {},
            {},
            {},
            {},
            {
              events: [
                {
                  title: "産業文化祭",
                  link: "https://oki-ama.org/2019-10-16/",
                  place: "菱浦港",
                },
              ],
            },
            {
              events: [
                {
                  title: "産業文化祭",
                  link: "https://oki-ama.org/2019-10-16/",
                  place: "菱浦港",
                },
              ],
            },
            {},
            {},
            {},
            {},
            {
              events: [
                {
                  title: "アママーレジャマイカ祭り",
                  link: "https://oki-ama.org/2019-10-16/",
                  place: "あまマーレ",
                },
                {
                  title: "島前高校文化祭",
                  link: "https://oki-ama.org/2019-10-16/",
                  place: "島前高校",
                },
                {
                  title: "ブータン来島",
                  link: "https://oki-ama.org/2019-10-16/",
                  place: "菱浦港",
                },
              ],
            },
            {},
            {},
            {},
            {},
            {},
            {},
          ],
        },
      ],
    };
  },
  computed: {},

  methods: {},
};
</script>

<style scoped>
.button {
  display: block;
  text-align: center;
  color: #fff;
  padding: 2px 8px;
  width: 100%;
  border-radius: 4px;
  background: #0089aa;
}

/*** カレンダー ***/
.g-calendar {
  margin: 0 10px;
}

.g-calendar iframe {
  width: 100%;
  height: calc(100vh - 208px);
}

.calendar-active .g-calendar {
  pointer-events: auto;
}

.calendar {
  width: 90%;
  margin: 10px auto;
  text-align: left;
}

.calendar-row {
  border-bottom: solid 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-content: center;
}

.calendar-date {
  font-size: 16px;
  padding-left: 10px;
}

.calendar-event {
  font-size: 12px;
  padding-left: 10px;
}

.calendar-event-title {
  pointer-events: auto;
}
</style>
